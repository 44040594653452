/* button {
  background-color: #fff;
  border: 1px solid #25D366;
  padding: 5px;
  position: relative;
  width: 7.5em;
  height: 2em;
  transition: 0.5s;
  font-size: 16px;
  border-radius: 0.4em;
  cursor: pointer
}

button p {
  position: absolute;
  top: 0.3em;
  left: 1.15em;
  margin: 0;
  padding: 0;
  transition: .5s;
  color: #25D366;
}

button svg {
  
  width: 20px;
  height: 20px!important;
  top: 5px;
  right: 15px;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: 0.5s;
  height: 1em;
  fill: #fff
}

button:hover p {
  left: 0.5em;
  color: #fff
}

button:hover svg {
  opacity: 1;
  color: white
}

button:hover {
  background-color: #25D366;
} */


/* styles.css */

@media only screen and (min-width: 601px) {
  .cardImgContainer {
    height: 400px;
  }
}

@media only screen and (min-width: 901px) {
  .cardImgContainer {
    height: 100%;
  }
}

.cardImage {
  width: 100%;
  height: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  object-fit: cover;
}

@media (max-width: 900px) {
  .cardImage {
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
  }
}



@media (min-width: 961px) {
  .cardImage {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;
  }
}