/* style.css */

/* Estilos para la tabla */
table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  /* Estilos para el encabezado de la tabla */
  th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  /* Estilos para las celdas de datos alternas */
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Estilos para botones */
  button {
    background-color: #4caf50; /* Color de fondo */
    color: white; /* Color del texto */
    padding: 10px 20px; /* Espaciado dentro del botón */
    border: none; /* Sin borde */
    cursor: pointer; /* Cambia el cursor al pasar el ratón */
    border-radius: 5px; /* Bordes redondeados */
  }
  
  button:hover {
    background-color: #45a049; /* Cambia el color de fondo al pasar el ratón */
  }
  
  
  /* Estilos para el contenedor de botones de paginación */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    margin: 0 5px;
  }
  