.loader {
  overflow: hidden;
  position: relative;
}

.wheel {
  fill: rgb(128, 128, 128);
  font-size: 100px;
  width: 100px;
  height: 100px;
  border-radius: 6em;
  text-align: center;
  animation: rolling 1s linear infinite;
}

#rim {
  fill: rgb(213, 209, 209);
}

#tire {
  fill: rgb(151, 150, 150);
}

.road {
  position: absolute;
  margin-top: -4px;
  width: 100%;
  height: 10px;
  border-radius: 5em;
  background-color: rgb(222, 219, 219); /* Cambié el color a un tono más claro */
  animation: road-move 2s linear infinite;
}

@keyframes rolling {
  to {
      transform: rotate(-360deg);
  }
}

@keyframes road-move {
  from {
      transform: translateX(-140%);
  }

  to {
      transform: translateX(100%);
  }
}
