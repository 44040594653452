.card {
  width: 280px;
  height: 400px;
  background: rgba(72, 75, 92, 0.15);
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  border: 1px solid rgba(72, 75, 92, 0.075);
  border-radius: 10px;
  box-shadow: 0px 0px 14px -2px #FF9800;
  transition: 0.2s ease-in-out;
}

.card:hover {
  cursor: pointer;
}

.imgContainer {
  width: 100%;
  height: 12em;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: linear-gradient(#7980c5, #9198e5);
  display: flex;
  align-items: top;
  justify-content: right;
}

.img {
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.save {
  transition: 0.2s ease-in-out;
  border-radius: 10px;
  margin: 20px;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.save .svg {
  transition: 0.2s ease-in-out;
  width: 15px;
  height: 15px;
}

.save:hover .svg {
  fill: #ced8de;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 160px;
  justify-content: center;
  position: relative;
}

.text .h3 {
  font-family: system-ui;
  font-size: medium;
  font-weight: 600;
  color: white; /* Adjust text color to contrast with the background */
  text-align: center;
}

.text .p {
  font-family: system-ui;
  color: #999999;
  font-size: 13px;
  margin: 0px;
  text-align: center;
  padding: 5px;
}

.icon-box {
  padding: 8px;
  border-radius: 10px;
  text-align: center;
}

.icon-box .span {
  font-family: system-ui;
  font-size: small;
  font-weight: 500;
  color: #fff;
}

.button-container {
  margin-top: 10px;
}
.divider {
  margin: 10px 0;
  background-color: rgba(255, 255, 255, 0.5);
}