.btn {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border: none;
  position: relative;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 5px .2px #00000035;
  box-shadow: 1px 1px 5px .2px #00000035;
  -webkit-transition: .2s linear;
  transition: .2s linear;
  transition-delay: .2s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-between;
  background-color: #404040;
}

.btn:hover {
  width: 200px;
  transition-delay: .2s;
}

.btn:hover > .paragraph {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: .4s;
  transition-delay: .4s;
}

.btn:hover > .icon-wrapper .icon {
  transform: scale(1.1);
}

.bnt:hover > .icon-wrapper .icon path {
  stroke: black;
}

.paragraph {
  color: #fff;
  visibility: hidden;
  opacity: 0;
  font-size: 14px;
  margin-right: 20px;
  padding-left: 20px;
  -webkit-transition: .2s linear;
  transition: .2s linear;
  font-weight: bold;
}

.icon-wrapper {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff
}

.icon {
  transform: scale(.9);
  transition: .2s linear;
}

.icon path {
  stroke: #000;
  stroke-width: 2px;
  -webkit-transition: .2s linear;
  transition: .2s linear;
}