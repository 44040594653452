.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  color: white;
  text-decoration: none;
  padding: 8px 12px;
  border: 1px solid white;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.page-link:hover {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.page-link:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

@media (max-width: 768px) {
  .pagination {
    flex-wrap: wrap;
  }

  .page-item {
    margin: 5px;
  }
}
