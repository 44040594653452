.skeleton {
    width: 280px;
    height: 370px;
    background: rgba(72, 75, 92, 0.15);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    border: 1px solid rgba(72, 75, 92, 0.075);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .imgContainer {
    width: 100%;
    height: 12em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #3d3c3c; /* Color de fondo gris más claro */;
    display: flex;
    align-items: top;
    justify-content: right;
  }
  
  .text {
    padding: 20px;
  }
  
  .skeleton-content {
    width: 100%;
    height: 20px;
    background: #3d3c3c; /* Color de fondo gris más claro */
    margin-bottom: 10px;
  }
  
  /* .skeleton-content:last-child {
    margin-bottom: 0;
  
  
 */
  .skeleton-buttons {
    width: 90px;
    height: 40px;
    background: #3d3c3c; /* Color de fondo gris más claro */
    margin-bottom: 10px;
  }